<template>
  <div class="sf-carousel">
    <div v-if="isShowArrows" class="sf-carousel__controls">
      <!--@slot slot for icon moving to the previous item -->
      <slot name="prev" v-bind="{ go: () => go('prev'), isFirst }">
        <SfArrow
          aria-label="previous"
          class="sf-arrow--long"
          data-transaction-name="Carousel - Prev"
          @click="go('prev')"
        />
      </slot>
      <!--@slot slot for icon moving to the next item -->
      <slot name="next" v-bind="{ go: () => go('next'), isLast }">
        <SfArrow
          aria-label="next"
          class="sf-arrow--long sf-arrow--right"
          data-transaction-name="Carousel - Next"
          @click="go('next')"
        />
      </slot>
    </div>
    <div class="sf-carousel__wrapper">
      <div ref="glide" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides sf-carousel__slides">
            <!--@slot default slot for SfCarouselItem tags -->
            <slot />
          </ul>
        </div>
      </div>
    </div>
    <div v-if="numberOfPages > 1 && isShowBullets" class="sfo-carousel__bullets">
      <!--@slot custom markup for pagination bullets -->
      <slot name="bullets" v-bind="{ numberOfPages, page, go }">
        <SfBullets
          :total="numberOfPages"
          :current="page - 1"
          data-transaction-name="Carousel - Bullet Click"
          @click="go($event)"
        >
          <template #inactive="{ index, go }">
            <li :key="index">
              <SfButton
                :aria-label="'Go to slide ' + (index + 1)"
                class="sf-button--pure sf-bullet"
                @click="go(index)"
              ></SfButton>
            </li>
          </template>
          <template #active>
            <li>
              <SfButton
                aria-label="Current slide"
                class="sf-button--pure sf-bullet sf-bullet--active"
              ></SfButton>
            </li>
          </template>
        </SfBullets>
      </slot>
    </div>
  </div>
</template>
<script>
import GlideInit from 'theme/helpers/glide';
import { SfArrow, SfBullets, SfButton } from '@storefront-ui/vue';
import Vue from 'vue';
import SfCarouselItem from '@storefront-ui/vue/src/components/organisms/SfCarousel/_internal/SfCarouselItem';
Vue.component('SfCarouselItem', SfCarouselItem);
export default {
  name: 'SfOCarousel',
  components: {
    SfButton,
    SfArrow,
    SfBullets
  },
  props: {
    /** Carousel options like glide.js (https://glidejs.com/docs/) */
    settings: {
      type: Object,
      default: () => ({})
    },
    isShowBullets: {
      type: Boolean,
      default: false
    },
    isShowArrows: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      glide: null,
      isFirst: true,
      isLast: false,
      defaultSettings: {
        type: 'carousel',
        rewind: true,
        perView: 4,
        slidePerPage: true
      }
    };
  },
  computed: {
    mergedOptions () {
      let breakpoints = { ...this.defaultSettings.breakpoints };
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints };
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints: breakpoints
      };
    },
    page () {
      if (this.glide) {
        return this.glide.index + 1;
      }
      return 1;
    },
    numberOfPages () {
      return this.$slots.default
        ? this.$slots.default.filter((slot) => slot.tag).length
        : 0;
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      if (!this.$slots.default || !this.$refs.glide) return;
      this.initGlide()
    });
  },
  beforeDestroy () {
    try {
      if (this.glide) {
        this.glide?.destroy()
      }

      this.glide = null
    } catch (e) {}
  },
  methods: {
    go (direct) {
      if (!this.glide) return;
      switch (direct) {
        case 'prev':
          this.glide.go('<');
          break;
        case 'next':
          this.glide.go('>');
          break;
        default:
          this.glide.go(`=${direct}`);
          break;
      }
    },
    async initGlide () {
      if (!this.$slots.default || !this.$refs.glide) return;
      const Glide = await GlideInit();
      const glide = new Glide(this.$refs.glide, this.mergedOptions);
      glide.mount();

      const size = this.$slots.default.filter((slot) => slot.tag).length;

      this.isFirst = true
      this.isLast = glide?.settings.perView >= size

      glide.on('run.before', (move) => {
        const { slidePerPage, rewind, type } = this.mergedOptions;
        if (!slidePerPage) return;
        const { perView } = glide.settings;
        if (!perView > 1) return;
        const size = this.$slots.default.filter((slot) => slot.tag).length;
        const { direction } = move;
        let page, newIndex, pages;
        switch (direction) {
          case '>':
          case '<':
            page = Math.ceil(glide.index / perView);
            pages = Math.ceil(size / perView);
            newIndex =
              page * perView + (direction === '>' ? perView : -perView);
            if (newIndex >= size) {
              if (type === 'slider' && !rewind) {
                newIndex = glide.index;
              } else {
                newIndex = 0;
              }
            } else if (newIndex < 0 || newIndex > size) {
              if (type === 'slider' && !rewind) {
                newIndex = glide.index;
              } else {
                newIndex = size - perView;
              }
            }

            const nextPage = Math.ceil(newIndex / perView) + 1;

            this.isFirst = nextPage === 1
            this.isLast = nextPage === pages

            move.direction = '=';
            move.steps = newIndex;
        }
      });

      glide.on('run.after', () => {
        this.$emit('slide-change', glide.index);
      })

      this.$emit('slide-change', glide.index);

      this.glide = glide;
    }
  }
};
</script>
<style lang="scss">
@import "$ui/styles/components/organisms/SfCarousel.scss";
</style>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";

.sfo-carousel__bullets {
  display: flex;
  justify-content: center;
  padding-top: var(--spacer-10);

  @media (min-width: $tablet-min) {
    padding-top: var(--spacer-15);
  }
}
</style>
